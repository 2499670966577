* {
  box-sizing: border-box;
}

html, body {
  position: relative;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

main {
  flex: 1;
}

body {
  @apply 
  dark:bg-agantar-dark-bg 
  text-agantar-text 
  dark:text-agantar-text-white;
}

.page {
  @apply 
  max-w-7xl 
  mx-auto 
  min-w-[320px]
  sm:px-4
  ;
}

.last {
  @apply 
    mb-6
    ;
}

/* Base font */
p, ul, h1, h2, h3, h4, h5, h6, a, button {
  @apply 
    font-base
    ;
  font-optical-sizing: auto;
  font-weight: 400;
}

a.link {
  @apply 
    text-agantar-light-blue
    hover:underline
    transition-all
    
    ;
}

p{
  @apply 
    text-base
  ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

/* Display */
h1,
.display {
  @apply font-title
  font-bold
  
  text-5xl
  md:text-6xl
  md:leading-[62px]
  leading-[45px]

  md:mb-12
  mb-6
  ;
  font-optical-sizing: auto;
}

/* Title -  */
h2,
.title{
  @apply 
    font-title font-bold
    md:text-5xl
    md:mb-8
    text-4xl
    mb-6
    ;
}

/* Subtitle -  */
h3,
.subtitle {
  @apply 
    font-title
    text-2xl
    md:text-3xl
    ;
}

/* Heading - */
h4,
.heading {
  @apply 
    font-bold
    sm:pb-2
    sm:text-2xl
    text-xl
    ;
}

/* Subheading - */
h5,
.subheading {
  @apply 
    text-xl
    ;
}

h6, .small, .btn-small {
  @apply 
    text-sm
    leading-5
    font-light
    ;
}

caption {
  @apply 
    text-xs
    w-full
    text-left
    ;
    font-weight: 300;
}

b {
  @apply font-semibold;
}

.blue {
  @apply text-agantar-blue dark:text-agantar-light-blue;
}

ul, ol {
  @apply pl-4;
}
li{ @apply pl-1;}
ul { @apply list-disc; }
ol {  @apply list-decimal;}

ul.clear,
ul.clear li {
  @apply list-none m-0 p-0;
}


.card {
  @apply 
  bg-white dark:bg-gray-800
  border 
  border-gray-200 dark:border-gray-700
  rounded-lg shadow-md p-8;
}


/* BUTTONS */
.btn {
  @apply 
  block
  rounded
  ;
}

.cta {
  @apply 
    block
    py-4 px-6
    rounded
    bg-agantar-light-blue
    text-white
    hover:bg-agantar-blue
    transition-colors
    mx-auto
    sm:w-fit
    sm:min-w-80
    text-center
    ;
}

.btn.thin {
  @apply 
    w-fit
    py-1 px-4
    ;
}

.btn-blue {
  @apply 
    bg-agantar-light-blue
    text-white
    hover:bg-black/30
    transition
    ;
}

.btn.primary {
  @apply
    bg-agantar-light-blue
    text-white
    hover:bg-agantar-blue
    transition-colors
    ;
}

.btn.secondary {
  @apply
    text-black/80
    dark:text-agantar-text-white
    hover:text-white
    border-2 border-gray-200
    hover:border-agantar-light-blue
    hover:bg-agantar-light-blue
    rounded-lg
    transition-colors
    ;
}

.btn-small {
  @apply
    block
    text-xs
    p-1 px-2 rounded text-white
    bg-agantar-light-blue
    hover:bg-agantar-blue
    transition-colors
    ;
}

.middle {
  @apply 
  max-w-2xl 
  text-lg
  mx-auto 
  mb-16;
}

.btn-cta {
  @apply
    sm:text-base
    text-sm
    px-8 py-4 rounded-full
    border-2 
    w-fit
    transition-all duration-500
    ;
}

.btn-cta.primary{
  @apply
    text-white
    bg-agantar-light-blue 
    border-agantar-light-blue
    hover:bg-agantar-blue 
    hover:border-agantar-blue
  ;
}

.btn-cta.dark {
  @apply
    hover:bg-agantar-light-blue
    hover:border-agantar-light-blue
  ;
}

.btn-cta.secondary{
  @apply
    border-black/50
    hover:border-agantar-light-blue
    hover:border-agantar-light-blue
  ;
}

.btn-cta.ternary{
  @apply
    border-white
    hover:bg-agantar-light-blue
    hover:border-agantar-light-blue
  ;
}

.animate {
  animation: slideUp 1s ease-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.responsive-grid {
  @apply 
    grid 
    xl:grid-cols-3 lg:grid-cols-2 
    xl:gap-6 gap-4 
    items-start 
    md:max-w-full 
    max-w-[400px] 
    mx-auto 
    mb-12 
    w-fit
}

.responsive-header {
  @apply
  p-4 
  mx-auto 
  xl:max-w-full 
  lg:max-w-4xl 
  max-w-2xl
  xl:text-left text-center;
}

.responsive-card {
  @apply 
  bg-gray-50 dark:bg-gray-700/70
  border 
  border-gray-100  dark:border-gray-700
  rounded-xl 
  w-full 
  p-4  
  max-w-[400px];
}

.responsive-card-list {
  @apply mb-6;
}

.responsive-card-list *:first-child {
  @apply rounded-t;
}

.responsive-card-list *:last-child {
  @apply 
  rounded-b
  border-b-0
  ;
}

.responsive-card-list-item {
  @apply
  flex items-center items-start 
  gap-4
  p-3 
  bg-white 
  dark:bg-gray-800
  border-b
  border-gray-100 dark:border-gray-700
  ;
}