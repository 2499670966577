.glass {
    @apply mx-auto p-4 p-8 px-12 rounded-xl text-left flex-1 backdrop-blur-sm border shadow-lg
    bg-gray-50 dark:bg-transparent dark:bg-gradient-to-r dark:from-white/10 dark:to-white/5 dark:border-white/10
    md:min-w-[650px]
    ;
}

.info {
    @apply lg:border lg:border-black/5 bg-white/80 dark:bg-black/5 dark:border-white/10 rounded-xl text-left px-6 lg:p-8
    lg:max-w-96 mb-6 xl:m-0
  ;
}

label {
  @apply 
   block
   text-gray-500
   text-sm
   p-1
   ;
}

select {
  @apply 
    appearance-none
    w-full 
    text-gray-500 
    p-3 
    rounded-lg 
    text-black/80 
    bg-white 
    dark:text-white
    border 
    border-gray-200 
    dark:bg-white/5
    dark:border-black/15 
    dark:placeholder:text-white/60
  ;
  background-image: url('data:image/svg+xml;utf8,<svg fill="gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right .5rem top 50%;
  background-size: 1.5em;
  padding-right: 2.5em;
}

select::after {
  content : 'XXX';
  color: #000;
  position: absolute;
  right: 0;
  top: 0;
}